import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <header>
        <h2>Nora Niewerth</h2>
        <p>
          <a href="mailto:nn@nora-niewerth.de">nn@nora-niewerth.de</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <div>Hallo,<br></br>
      Hier ist Nora und dies ist meine Lebenslauf-Seite.<br></br>
      Hauptberuflich engagiere ich mich als Sozialpädagogin. In meiner Freizeit schlägt mein Herz für die digitale Welt.
      </div>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Nora Niewerth <Link to="/">nora-niewerth.de</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
