const routes = [
  {
    index: true,
    label: "Nora Niewerth",
    path: '/',
  },
  {
    label: 'Lebenslauf',
    path: '/',
  },
  {
    label: 'Kontakt',
    path: '/contact',
  },
];

export default routes;
